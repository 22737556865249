
.bold{
    font-weight: bold;
}

.black,
.black:hover {
    color: black !important;
}

.blue,
.blue:hover {
    color: $blue !important;
}

.dark-gray,
.dark-gray:hover {
    color: $darkest-gray;
}

.green,
.green:hover {
    color: $green;
}

.orange,
.orange:hover {
    color: $orange;
}

.red,
.red:hover {
    color: $basic-red;
}

h4 {
    font-weight: bold;
}