
// mobile
@media only screen and (max-width: $screen-xs-max) {
    .checkout-main-header {
        background-color: $collections-green;
    }

    .checkout-mobile-header-logo {
        .checkout-header-title {
            color: $white;
            text-decoration: none;
            border-bottom: none;
            font-size: 16px;
            font-weight: 700;
            display: flex;
            align-items: center; 
            padding-bottom: 0;
            margin-top: 10px;
            
            svg {
                fill: $white;
                margin-right: 8px;
            }
        }
    }

    .checkout-mobile-header {
        &-row {
            align-items: center;
            display: flex;
            justify-content: space-between;

            margin: {
                bottom: 0;
                top: 0;
            }
        }

        &-log,
        &-logo,
        &-contact {
            flex-grow: 1;
            flex-shrink: 1;
        }

        &-log {
            flex-basis: 30%;
            display: none;

            a {
                color: $green;
            }
        }

        &-logo,
        &-contact {
            flex-basis: 35%;
        }

        &-notyou,
        &-hours {
            font-size: smaller;
        }

        &-contact {
            text-align: right;
        }

        &-phone {
            display: block;
            font-weight: 600;

            &.mobile {
                font-weight: 400;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                svg {
                    color: $white;
                    width: 22px;
                    margin-right: 8px;
                }

                &:active,
                &:focus,
                &:hover {
                    text-decoration: none;
                }

                .checkout-mobile-header-fa-phone {
                    color: $white;
                    margin-right: 8px;
                }
            }
        }

        &-fa-phone {
            font-size: 1.4em;
        }

        &-hours {
            display: block;
            color: $white;
        }
    }
}
// tablets and desktops
@media only screen and (min-width: $screen-sm-min) {
    #header-checkout {
        #branding {
            border-bottom: 1px solid $collections-green;
            margin-top: 13px;

            .checkout-header {
                &-log-text-column,
                &-phone-icon {
                    font-size: 15px;
                }

                &-logout-text,
                &-hours {
                    font-size: smaller;
                }

                &-icons,
                &-phone-icon {
                    text-align: right;
                }

                &-phone-icon {
                    display: inline-block;
                    padding-left: 15px;

                    a {
                        display: flex;
                        align-items: baseline;

                        svg {
                            width: 30px;
                            color: $collections-green;
                        }
                    }
                }

                &-phone-number {
                    font-weight: 600;
                }

                &-hours {
                    display: block;
                }

                &-logo {
                    width: 113px;
                }

                &-title {
                    color: $collections-green;
                    margin-top: 20px;
                    padding-bottom: 0;
                    border-bottom: none;
                    text-align: center;
                }
            }
        }
    }
}
// tablets
@media only screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    #header-checkout {
        #branding {
            margin: {
                left: -10px;
                right: -10px;
            }

            [class*="col-"] {
                padding: {
                    left: 10px;
                    right: 10px;
                }
            }

            .checkout-header {
                &-log-text-row {
                    margin: {
                        left: -10px;
                        right: -10px;
                    }
                }

                &-log-text-column {
                    padding: {
                        left: 10px;
                        right: 10px;
                    }
                }

                .checkout-header-logo {
                    width: 50%;
                    margin: 0 auto;
                }
            }
        }
    }
}
