﻿
.modal-dialog {
    .modal-content {
        .modal-header {
            border-bottom: none;
        }

        .modal-body {
            .media-heading {
                font-weight: bold;
            }

            .product-name, .cart-modal-details {
                margin: 2px 0;
            }

            .cart-subtotal {
                font-size: 16px;
                font-weight: bold;
                margin-left: 4px;
            }

            .triangle {
                color: $basic-red;
            }
        }

        .modal-footer {
            border-top: none;
            text-align: center;
            padding-bottom: 15px;

            .btn {
                @media only screen and (max-width: $screen-xs-max) {
                    font-size: 13px;
                    margin-bottom: 10px;
                }

                + .btn {
                    margin-left: 15px;

                    @media only screen and (max-width: $screen-xs-max) {
                        margin-left: 8px;
                    }
                }
            }
        }
    }
}
