﻿
#main-nav {
    border: 1px solid $light-gray;

    > ul > li > a {
        font-weight: bold;
        text-transform: uppercase;
        user-select: none;
    }
}

#mobile-nav {
    background-color: $white;
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
    width: 90%;
    display: none;
}

.animated.slideInLeft {
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
}

#mobile-background {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    opacity: 0;
    z-index: 1;
    background-color: $black;
    opacity: .65;
    display: none;
}

nav.navbar-default {
    background-color: $white;
    border: none;
    border-radius: 0;
    height: 45px;
    margin-bottom: 0;
    z-index: 999;

    a > .fa {
        color: white;
    }

    .navbar-header {
        .navbar-toggle {
            border-color: $beige;

            .icon-bar {
                background-color: white;
            }
        }

        @media (max-width: $screen-xs-max) {
            .navbar-toggle:active,
            .navbar-toggle:hover {
                border-color: $beige;

                > .icon-bar {
                    background-color: $white;
                }
            }
        }
    }

    .navbar-collapse {
        background-color: $white;
        padding-left: 0;

        .navbar-nav {
            @media (min-width: $screen-sm-min) {
                display: flex;
                align-items: stretch; /* Default */
                width: 100%;
                min-height: 50px;
                align-items: center;
                max-height: 50px;
            }


            > li {
                @media (min-width: $screen-sm-min) {
                    display: block;
                    flex: 0 1 auto; /* Default */
                    list-style-type: none;
                    text-align: center;
                    margin-right: auto;
                }

                > a {
                    background-color: $transparent;
                    color: $darkest-gray;
                    font-weight: 600;
                    user-select: none;
                    padding-top: 0;
                    padding-bottom: 0;
                    line-height: 1.2;

                    .fa-bars {
                        padding-right: 10px;
                    }
                }

                > a:active,
                > a:focus,
                > a:hover {
                    text-decoration: underline;
                }
            }

            > li.dropdown.open {
                > a,
                > a:focus,
                > a:hover {
                    background-color: $darkest-gray;
                    color: white;
                }
            }

            @media (max-width: $screen-xs-max) {
                > li {
                    padding-left: 10px;

                    > .dropdown-menu {
                        border-radius: 0;
                    }
                }
            }
        }

        @media (max-width: $screen-xs-max) {
            .navbar-nav {
                margin-bottom: 0;
                margin-top: 0;

                .navbar-link:not(:last-of-type) a {
                    border-bottom: 1px solid $darkest-gray;
                }
            }
        }
    }
}

@media (max-width: $screen-xs-max) {
    nav.navbar-default {
        background-color: $transparent;
        font-size: 16px;
        height: 0;
        min-height: 0;
        padding-left: 15px;
        padding-right: 15px;

        .navbar-collapse {
            background-color: white;
            border-radius: 0 4px 4px 4px;
            margin-top: -100px;
        }
    }
}


#all-departments-link {
    color: $white;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 38px;
    min-width: 220px;
    line-height: 1.5;
    border-top: 1px solid $darkest-gray;

    @media (min-width: $screen-sm-min) {
        .menu-text {
            box-shadow: 3px 0 0 1px white, 3px 4px 0 1px white, 3px 8px 0 1px white;
            display: block;
            font-size: 9px;
            height: 0;
            left: 16px;
            line-height: 35px;
            margin-top: 1px;
            position: absolute;
            width: 2rem;
        }
    }
}

    @media (max-width: $screen-xs-max) {
        .navbar-link {
            a {
                padding-top: 15px !important;
                padding-bottom: 15px !important;
            }
        }
    }



    header#header-default #branding .navbar-toggle[aria-expanded=true] {
        background-color: $white;
        border-color: $white;
        border-radius: 4px 4px 0 0;
    }
