/* FONT PATH
 * -------------------------- */

/*@font-face {
  font-family: 'FontAwesome';
  src: url('#{$fa-font-path}/fontawesome-webfont.eot?v=#{$fa-version}');
  src: url('#{$fa-font-path}/fontawesome-webfont.eot?#iefix&v=#{$fa-version}') format('embedded-opentype'),
    url('#{$fa-font-path}/fontawesome-webfont.woff2?v=#{$fa-version}') format('woff2'),
    url('#{$fa-font-path}/fontawesome-webfont.woff?v=#{$fa-version}') format('woff'),
    url('#{$fa-font-path}/fontawesome-webfont.ttf?v=#{$fa-version}') format('truetype'),
    url('#{$fa-font-path}/fontawesome-webfont.svg?v=#{$fa-version}#fontawesomeregular') format('svg');
//  src: url('#{$fa-font-path}/FontAwesome.otf') format('opentype'); // used when developing fonts
  font-weight: normal;
  font-style: normal;
}*/
@import 'variables';

@font-face {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 900;
    font-display: $fa-font-display;
    src: url('#{$fa-font-path}/fa-solid-900.eot');
    src: url('#{$fa-font-path}/fa-solid-900.eot?#iefix') format('embedded-opentype'), url('#{$fa-font-path}/fa-solid-900.woff2') format('woff2'), url('#{$fa-font-path}/fa-solid-900.woff') format('woff'), url('#{$fa-font-path}/fa-solid-900.ttf') format('truetype'), url('#{$fa-font-path}/fa-solid-900.svg#fontawesome') format('svg');
}

.fa,
.fas {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}
