﻿
/*
    The thrive header html element placeholder in the base layout needs to accomplish the following:
    1). the foreground and background colors need to allow an overlay to appear (i.e., white, #fff is the hex code);
    2). the height needs to be the same as an overlay (i.e., 42px);
    3). the positioning needs to keep the placeholder element at the top of the page (i.e., static, which is the default); and
    4). the width needs to be the entire viewport (i.e., 100%).
*/
.thrive-header {
    color: #fff;
    background-color: #fff;
    height: 42px;
    width: 100%;
}
