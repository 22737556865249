﻿
.btn-green {
    @include button-colors($collections-green, $collections-green);
    transition: .2s ease-in-out;

    &:active,
    &:focus,
    &:hover {
        @include button-colors($darker-green, $darker-green);
    }
}

.btn-orange {
    @include button-colors($orange, darken($orange, 5%));
    transition: .2s ease-in-out;

    &:active,
    &:focus,
    &:hover {
        @include button-colors(darken($orange, 5%), darken($orange, 7%));
    }
}

.btn-secondary {
    @include button-colors($collections-green, $collections-green);
    border-width: 2px;
    font-weight: 700;
    transition: .2s ease-in-out;

    &:active,
    &:focus,
    &:hover {
        @include button-colors($darker-green, $darker-green);
    }
}

.btn-secondary-inverse {
    @include button-colors($white, $mostly-darker-green, $mostly-darker-green);
    font-weight: 700;
    border-width: 2px;
    transition: .2s ease-in-out;

    &:hover {
        @include button-colors($mostly-darker-green, $mostly-darker-green);
    }
}

.btn-trigger {
    background-color: $transparent;
    border: 0;
    padding: 0;
}

.ce-btn-lg {
    font: {
        weight: 600;
    }

    padding: {
        bottom: 12px;
        left: 20px;
        right: 20px;
        top: 12px;
    }
}

.ce-btn-primary {
    @include button-colors($collections-green, $collections-green);
    transition: .2s ease-in-out;

    &:active,
    &:focus,
    &:hover {
        @include button-colors($darker-green, $darker-green);
    }
}

.btn-addtocart {
    @include button-colors($collections-green, $collections-green);
    transition: .2s ease-in-out;

    &:active,
    &:focus,
    &:hover {
        @include button-colors($darker-green, $darker-green);
    }

    &.lg {
        font-size: 18px;
        line-height: 1.3333333;
        padding: 10px 16px;
    }

    &.bld {
        font-weight: 700;
    }
}

.btn-large,
.btn-small {
    @include button-colors($collections-green, $collections-green);
    font-size: 18px;
    font-weight: 700;
    padding: 10px 20px 11px;
    transition: .2s ease-in-out;
    width: 100%;

    &:active,
    &:focus,
    &:hover {
        @include button-colors($darker-green, $darker-green);
    }

    &.error {
        background-color: $basic-red;
        border-color: $basic-red;
    }
}

.btn-large {
    height: 50px;
    max-width: 327px;
}

.btn-small {
    font-size: 18px;
    font-weight: 700;
    height: 45px;
    max-width: 187px;
    padding-top: 8px;
    padding-bottom: 9px;

    &.fluid {
        max-width: none;
        min-width: 187px;
        width: auto;
    }
}

.btn-accent {
    background: none;
    color: $collections-green;
    text-decoration: underline;

    &.error {
        color: $basic-red;
    }
}

.btn-outlined {
    background-color: $white;
    color: $collections-green;
}

.btn-disabled {
    background-color: $mid-tone-gray;
    border-color: $mid-tone-gray;
}
