﻿
$flyout-offset: -41px;

.nav-all-departments {
    > a,
    > a:link,
    > a:visited,
    > a:hover,
    > a:active,
    > a:focus {
        background-color: $darkest-gray !important;
    }
}

@media (max-width: $screen-xs-max) {
    .nav-all-departments {

        a {
            font-size: 16px !important;
            padding-top: 15px !important;
            padding-bottom: 15px !important;
        }

        @media (min-width: $screen-sm-min) {
            a {
                align-items: center;
                padding-top: 15px;
                padding-bottom: 15px;
                min-height: 50px;
            }
        }


        > .dropdown-menu {
            border: none;
            display: block !important;
            float: none;
            padding: 0;
            position: static;
            width: auto;
        }

        .nav-root-category {
            &:hover {
                > .dropdown-menu {
                    display: block;
                }
            }

            .nav-category-flyout {
                background-color: white;
                float: none;
                left: 0;
                min-width: 251px !important;
                overflow-x: hidden;
                padding-left: 15px;
                position: static;
                top: 100%;
                width: 100%;
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    .stay-open {
        display: block !important;
    }

    .nav-all-departments {
        width: 220px;

        > .dropdown-menu {
            border: none;
            box-shadow: none;
            padding-bottom: 0 !important;
            padding-top: 0 !important;
            width: 220px;

            .nav-root-category {
                @for $i from 1 through 12 {
                    &:nth-of-type(#{$i}) .nav-category-flyout {
                        top: $flyout-offset * ($i - 1);
                    }
                }
            }
        }
    }
}

.nav-root-category,
.navbar-default .navbar-nav .open > .dropdown-menu > li {
    position: relative;

    &:hover {
        > .nav-category-flyout {
            display: block;

            > [class^="col-"],
            > [class*="col-"] {
                background-color: white;
            }
        }

        > a:after {
            border-left-color: $white;
        }

        @media(min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            > .nav-category-flyout {
                width: 500px;
            }
        }

        @media(min-width: $screen-md-min) and (max-width: $screen-md-max) {
            > .nav-category-flyout {
                width: 720px;
            }
        }

        @media(min-width: $screen-lg-min) {
            > .nav-category-flyout {
                width: 920px;
            }
        }
    }

    > a,
    > a:link,
    > a:visited {
        background-color: $beige;
        color: black;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 1px;
        padding-bottom: 10px;
        padding-top: 10px;
        padding: 10px 20px;
        position: relative;
        text-transform: uppercase;

        > .nav-arrow {
            color: $dark-beige;
        }
    }

    > .dropdown-toggle {
        > .nav-arrow {
            line-height: 1;
            margin-top: -10px;
            position: absolute;
            right: 20px;
            top: 50%;
        }
    }


    > .nav-category-flyout {
        background-color: $transparent;
        border: none;
        box-shadow: none;
        left: 100%;
        padding: 0;
        margin-top: 0;
        margin-left: -1px;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;

        > [class^="col-"],
        > [class*="col-"] {
            background-color: white;
            padding: 0;

            > .container-column {
                margin: 10px 0;
                padding: 0;

                a.navigation-banner {
                    padding: 0;
                }
            }
        }

        @media (max-width: $screen-xs-max) {
            > [class^="col-"],
            > [class*="col-"] {
                > .container-column {
                    margin: 0;
                }
            }
        }

        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            > [class^="col-"],
            > [class*="col-"] {
                border-left: 1px solid rgba(0,0,0,.15);
                border-right: 1px solid rgba(0,0,0,.15);

                > .container-column {
                    border-top: 1px solid $light-gray;
                }
            }
        }

        @media (min-width: $screen-md-min) {
            > [class^="col-"],
            > [class*="col-"] {
                border-bottom: 1px solid rgba(0,0,0,.15);
                border-top: 1px solid rgba(0,0,0,.15);
                height: 550px;
                overflow: hidden;

                &:first-child {
                    border-left: 1px solid rgba(0,0,0,.15);
                }

                &:last-child {
                    border-right: 1px solid rgba(0,0,0,.15);
                }

                &:not(:last-child) > .container-column {
                    border-right: 1px solid $light-gray;
                }

                > .container-column {
                    height: 526px;

                    a.navigation-banner {
                        margin-top: -10px;
                    }
                }
            }
        }
    }
}

.nav-sub-category {
    padding: 0 4px;

    > a {
        margin: 2px 4px;
        padding: 2px 8px;
        color: $darkest-gray;
        font-size: 16px;
        font-weight: bold;
        text-decoration: none;
        display: block;
    }

    > a:hover {
        color: darken($darkest-gray, 5%);
        background-color: $darker-than-darkest-beige;
    }

    > ul {
        color: black;
        font-weight: normal;
        margin-top: 0;
        padding: 0 0 10px 0;
    }
}

.nav-sub-sub-category {
    font-size: 14px;
    color: black;
    font-weight: normal;
    margin-left: 8px;
    margin-right: 8px;
    padding: 4px 8px;

    &:hover {
        background-color: $darker-than-darkest-beige;
    }

    a {
        display: block;
        text-decoration: none;
    }
}

.nav-additional-links > a {
    color: red;
    font-weight: bold;
}

.mobile-nav-list {
    background-color: $white;
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;

    a {
        color: $btn-default-color;
        display: block;
        font-weight: 600;
        height: 30px;
        line-height: 30px;
        overflow: hidden;
        padding: 0 36px 0 6px;
        position: relative;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:active,
        &:focus {
            text-decoration: none;
        }

        &.mobile-category {
            background-color: $green;
            color: $white;

            &:not(.site-menu) {
                height: 38px;
                line-height: 38px;
                background-color: $darken-green;
                padding: 0 0 0 11px;
                font-size: 16px;
            }
        }

        &:not(.site-menu) {
            i {
                right: 5px;
                font-size: 10px;
                color: $white;
            }
        }

        i {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 11px;
            font-weight: bold;
        }

        & + .mobile-nav-list {
            a {
                padding-left: 14px;
            }
        }
    }
}

#mobile-secondary li a i {
    color: $darker-than-darkest-gray;
}

.mobile-close-button {
    color: $white;
    background-color: $transparent;
    border: 0;
    font-size: 15px;
    padding: 0;
    position: absolute;
    top: 19px;
    transform: translateY(-50%);
    right: 12px;
    z-index: 1;
    font-weight: bold;
}

.mobile-nav-back {
    &:before {
        content: "\f053";
        font-family: "Font Awesome 5 Free";
        padding-right: 10px;
        font-size: 10px;
        position: relative;
        top: -2px;
    }
}

a[data-brand-id="TYNKE"] + ul#mobile-menu ul.mobile-nav-list a i {
    color: $black;
}

a[data-brand-id="CE"] + ul#mobile-menu ul.mobile-nav-list a i {
    color: $black;
}

[data-brand-id="TYNKE"] + .mobile-nav-list a.mobile-category {
    color: $black;
}
