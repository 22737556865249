
.header-banner {
    &.container-fluid {
        padding: {
            left: 0;
            right: 0;
        }

        .row {
            margin: {
                left: 0;
                right: 0;
            }
        }

        [class*="col-"] {
            padding: {
                left: 0;
                right: 0;
            }
        }
    }
}

#header-default {
    #branding {
        padding: 10px 0;

        @media (min-width: $screen-sm-min) {
            padding-top: 0;
        }

        .navbar-toggle {
            background-color: $transparent;
            border-color: $white;
            color: $darker-than-light-gray;
            float: left;
            margin-bottom: 0;
            padding-bottom: 0;
            font-weight: 700;

            .icon-bar {
                background-color: $green;
                margin-left: auto;
                margin-right: auto;
                height: 3px;
            }
        }

        .navbar-toggle[aria-expanded="true"] {
            background-color: $light-gray;
            border-color: $gray-blue;
            border-radius: 4px 4px 0 0;
        }

        #container-icons ul.nav {
            z-index: 1000;

            @media (min-width: $screen-sm-min) {
                white-space: nowrap;
                display: flex;
                justify-content: space-evenly;
            }
        }
    }
    // mobile
    @media (max-width: $screen-xs-max) {

        .hidden-search {
            margin-bottom: 20px;
        }

        #tippy-top {
            display: none;
        }

        #branding {
            position: relative;

            [class^="col-"],
            [class*="col-"] {
                height: 75px;
            }

            .header-content {
                height: auto;
                vertical-align: bottom;
            }

            #container-search {
                padding-left: 15px;
                padding-right: 15px;
            }

            #container-logo > .header-content-container,
            #container-search > .header-content-container {
                width: 100%;
            }

            #container-logo {
                padding: 0;
            }

            #container-icons {
                position: static;
            }

            .logo-container img {
                max-height: 90px;
            }

            .mobile-icons {

                > .header-content-container {
                    float: right;

                    > .header-content {
                        text-align: center;
                    }
                }

                .cart-circle,
                .cart-item-count {
                    width: 100%;
                    margin: 0;
                    left: 8px;
                    top: -5px;
                }

                .cart-circle {
                    font-size: 22px;
                }
            }

            #container-mobile-menu {
                .mobile-menu-button {
                    border: 0;
                    margin: 0 0 8px 0;
                    padding: 0;

                    .icon-label {
                        font-weight: 700;
                        font-size: 14px;
                        color: $green;
                    }
                }
            }

            .mobile-search-header-block {
                display: table;

                .search-logo-container {
                    display: table-cell;
                    height: 100%;
                    text-align: center;
                    vertical-align: bottom;
                }

                .search-link {
                    text-decoration: none;
                }
            }
        }
    }
    // tablets and desktops
    @media (min-width: $screen-sm-min) {
        #tippy-top {
            background-color: $white;
            color: $black;

            a {
                color: $black;
                font-size: $font-size-small;
                font-weight: 600;
                padding: 0 1% 1%;
            }
        }

        #branding {
            #container-icons,
            #container-search {
                padding-left: 0;
            }

            .mobile-search-header-block {
                display: none;
            }
        }
    }
    // tablets
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        #branding {
            #container-logo {
                height: 105px;

                .header-content {
                    vertical-align: bottom;
                }
            }

            #container-icons {
                > .header-content-container {
                    width: 100%;
                }

                .nav {
                    text-align: center;

                    #header-account-icon {
                        .media-left {
                            padding: 0 10px;
                        }
                    }

                    .header-icon {
                        top: 30px;
                        width: 80px;

                        a {
                            padding-left: 0;
                            padding-right: 0;
                        }
                    }

                    li {
                        .media-left {
                            padding-right: 0;
                        }
                    }
                }

                .media-body {
                    display: none;
                }

                .header-content li {
                    vertical-align: top;
                }
            }

            #container-logo > .header-content-container,
            #container-search > .header-content-container {
                width: 100%;
            }

            #container-search {
                top: 40px;
            }

            .mobile-search-header-block {
                display: none;
            }
        }

        .tippyTopBannerContainer {
            .MainHeadline {
                font-size: 1.1em;
                line-height: 1.7em;
                padding-left: 5%;
                display: block;
            }
        }
    }
    // desktops
    @media (min-width: $screen-md-min) {
        #branding {
            [class^="col-"],
            [class*="col-"] {
                height: 67px;
            }

            .header-content-container {
                width: 100%;
            }

            .logo-outer-container {

                > .logo-container {
                    position: absolute;
                    top: -35px;
                    width: 100%;

                    img {
                        height: 165px;
                    }
                }
            }

            #container-search {
                margin-left: -20px;
            }

            #container-icons {
                .nav {
                    li {
                        .media-body {
                            text-align: left;
                            line-height: 16px;
                        }
                    }
                }
            }
        }
    }
}

.MainHeadline {
    color: $white;
    font-size: 1.4em;
    font-weight: bold;
    line-height: 2.0em;
    font-size: 1.5em;
}

.SecondaryHeadline {
    color: $white;
    font-size: 1.4em;
    padding-left: 2%;
}

@media (max-width: $screen-xs-max) {
    .tippyTopBannerContainer {
        .MainHeadline {
            font-size: 1.1em;
            line-height: 1.7em;
            display: block;
        }
    }
}
