﻿
#filters-mobile {
    padding: 5px 8px 0;

    li {
        background-color: $beige;

        .box {
            /*color: $white;

            i {
                color: $white;
            }*/
        }
    }
}

.subfilters > li {

background-color: white !important;
    
}
.btn-filters {
    background-color: $kelly-green !important;
}

#mobile-filter-sort, #mobile-filters {
    background-color: $darker-beige;
    padding-bottom: 0;
}

#mobile-filter-sort{
    padding-bottom: 5px;
}

.btn-mobile{
    border-radius: 0 !important;
    text-transform: uppercase;
}

#filters-mobile.list-group{
    margin-bottom: 5px;
}

#filters-mobile > li > a > div > div> .box{
    color: $mobile-beige;
}
