﻿.fa-dropbox {
    color: $dropbox-blue;
}

.fa-dropbox:hover {
    color: darken($dropbox-blue, 5%);
}

.fa-facebook,
.fa-facebook-official,
.fa-facebook-square {
    color: $facebook-blue;
}

.fa-facebook:hover,
.fa-facebook-official:hover,
.fa-facebook-square:hover {
    color: darken($facebook-blue, 5%);
}

.fa-flickr {
    color: $flickr-pink;
}

.fa-flickr:hover {
    color: darken($flickr-pink, 5%);
}

.fa-google-plus,
.fa-google-plus-circle,
.fa-google-plus-square {
    color: $google-red;
}

.fa-google-plus:hover,
.fa-google-plus-circle:hover,
.fa-google-plus-square:hover {
    color: darken($google-red, 5%);
}

.fa-instagram {
    color: $instagram-blue;
}

.fa-instagram:hover {
    color: darken($instagram-blue, 5%);
}

.fa-linkedin,
.fa-linkedin-square {
    color: $linkedin-blue;
}

.fa-linkedin:hover,
.fa-linkedin-square:hover {
    color: darken($linkedin-blue, 5%);
}

.fa-pinterest,
.fa-pinterest-p,
.fa-pinterest-square {
    color: $pinterest-red;
}

.fa-pinterest:hover,
.fa-pinterest-p:hover,
.fa-pinterest-square:hover {
    color: darken($pinterest-red, 5%);
}

.fa-rss,
.fa-rss-square {
    color: $rss-orange;
}

.fa-rss:hover,
.fa-rss-square:hover {
    color: darken($rss-orange, 5%);
}

.fa-twitter,
.fa-twitter-square {
    color: $twitter-blue;
}

.fa-twitter:hover,
.fa-twitter-square:hover {
    color: darken($twitter-blue, 5%);
}

.fa-tumblr,
.fa-tumblr-square {
    color: $tumblr-blue;
}

.fa-tumblr:hover,
.fa-tumblr-square:hover {
    color: darken($tumblr-blue, 5%);
}

.fa-youtube,
.fa-youtube-play,
.fa-youtube-square {
    color: $youtube-red;
}

.fa-youtube:hover,
.fa-youtube-play:hover,
.fa-youtube-square:hover {
    color: darken($youtube-red, 10%);
}