body {
    font-family: $open-sans;
    overflow-y: scroll;
}

#main {
    margin: 0 auto 50px;
}

.full-width {
    width: 100%;
}

.content-centered {
    display: table;
    margin: 0 auto;
}

.row-padded {
    padding-bottom: 20px;
}

.content-right {
    float: right;
}

.content-left {
    float: left;
}

.icon-label {
    color: $gray;
    font-size: $font-size-small;
}

h2.section-title {
    background-color: $lightest-gray;
    color: $dark-gray !important;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 20px;
    padding: 10px;
}

.modal-body {
    .section-title:first-of-type {
        margin-top: 0;
    }
}

div.loading-overlay {
    display: none;
    text-align: center;
    background-color: rgba(255,255,255,.6);
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 100;
}

div.loading-overlay-message-container {
    display: inline-block;
    position: relative;
    height: 150px;
    width: 120px;
    top: 25%;
    background: $really-darker-gray;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSI5NyUiIHN0b3AtY29sb3I9IiMzYTM2MzMiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMmEyNzI1IiBzdG9wLW9wYWNpdHk9IjEiLz4KICA8L2xpbmVhckdyYWRpZW50PgogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==);
    //@todo:  begin --remove these browser extensions, we use gulp's auto prefixer plugin to generate these (if necessary)
    background: -moz-linear-gradient(left,$really-darker-gray 93%,$almost-black 100%);
    background: -webkit-gradient(linear,left top,right top,color-stop(93%,$really-darker-gray),color-stop(100%,$almost-black));
    background: -webkit-linear-gradient(left,$really-darker-gray 93%,$almost-black 100%);
    background: -o-linear-gradient(left,$really-darker-gray 93%,$almost-black 100%);
    background: -ms-linear-gradient(left,$really-darker-gray 93%,$almost-black 100%);
    //@todo:  end --remove these browser extensions, we use gulp's auto prefixer plugin to generate these (if necessary)
    background: linear-gradient(to right,$really-darker-gray 93%,$almost-black 100%);
    opacity: .75;
}

div.loading-overlay-message {
    display: inline-block;
    position: relative;
    top: 25%;
    color: rgba(255,255,255,.8);
}

.ajax-loading-overlay {
    display: none;
    text-align: center;
    background-color: $black;
    opacity: .55;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 1000;
}

[v-cloak] {
    display: none;
}

.centered-responsive-image {
    display: block;
    height: auto;

    margin: {
        left: auto;
        right: auto;
    }

    max-width: 100%;
}

.margin-top {
    margin: {
        top: 10px;
    }
}

.margin-top-0 {
    margin: {
        top: 0;
    }
}

.margin-bottom-0 {
    margin: {
        bottom: 0;
    }
}
