﻿
.container-subnav-page {
    padding-left: 20px;

    h4 {
        color: $green;
        font-weight: bold;
        margin-top: 20px;
    }

    ul.subnav-page-list {
        color: $collections-green;
        list-style-type: none;
        padding-left: 10px;

        li {
            padding: 2px 0;

            a {
                color: $collections-green;
                line-height: 30px;
                text-decoration: none;
            }

            strong {
                line-height: 30px;
            }

            a.active {
                color: black;
            }
        }
    }
}
