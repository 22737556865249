
header {
    .header-content-container {
        display: table;
        height: 100%;
        width: 100%;

        .header-content {
            display: table-cell;
            height: 100%;
            vertical-align: middle;
        }
    }
}