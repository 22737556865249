﻿
$hs: "header-search";

##{$hs}-form {

    ##{$hs}-box {
        height: 44px;
        z-index: auto;
        font-size:16px;
    }

    .header-go-button {
        border-radius: 0;
        height: 44px;
        margin-left: 0;
        z-index: auto;
    }

    #clear-search {
        color: $almost-lightest-gray;
        font-size: 2.1rem;
        position: absolute;
        right: 62px;
        top: 12px;
    }
}

// tablets and desktops
@media only screen and (min-width: $screen-sm-min) {
    ##{$hs}-form {
        ##{$hs}-box {
            border-radius: 5px 0 0 5px;
        }

        .#{$hs}-button {
            border-radius: 0 5px 5px 0;
            height: 44px;
            margin-left: 0;
            z-index: auto;
        }

        #clear-search {
            right: 85px;
        }
    }
}

// desktops
@media only screen and (min-width: $screen-md-min) {
    ##{$hs}-form {
        #clear-search {
            &:hover {
                cursor: pointer;
            }
        }
    }
}
