﻿.fa-gold {
    color: $gold;
}

.fa-green {
    color: $collections-green;
}


.fa.fa-gray {
    color: $gray;
}

a,
a:link,
a:visited {
    .fa.fa-green {
        color: $collections-green;
    }
}

a:active,
a:focus,
a:hover {
    .fa.fa-green {
        color: $darker-green;
    }
}
