﻿
.breadcrumb {
    margin-bottom: 0 !important;

    > li {
        + li:before {
            font-family: "Font Awesome 5 Free";
            content: "\f054";
            font-weight: 900;
        }
    }
}

.breadcrumb-item {
    > a,
    &.active {
        color: $black;
    }
}