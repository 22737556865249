﻿
li.header-icon {
    display: inline-block;


    a:active,
    a:focus,
    a:hover,
    a:link {
        background-color: inherit;
    }

    .media {
        display: inline-block;
        min-width: 55px;

        .media-left {
            width: 42px;
            height: 32px;

            @media (max-width: $screen-xs-max) {
                width: 100%;
                text-align: right;
                display: inline-block;
                padding-right: 0;
            }


            i {
                height: 32px;
            }
        }
    }

    .media-left {

        .fa-2half {
            font-size: 2.25em;
        }
    }

    .media-body {
        color: $black;
        width: auto;
        text-align: left;
    }

    .media-heading {
        color: $darker-than-darkest-gray;
        display: block;
        margin-bottom: 0;

        &.header {
            color: $black;
        }
    }

    .media-sub-heading {
        font-size: $font-size-small;
    }


    .cart-item-count {
        color: $basic-red;
    }
}

@media (min-width: $screen-sm-min) {
    li.header-icon {


        a {
            display: inline-block;
            padding-left: 10px;
            padding-right: 10px;
            text-decoration: none;
            padding-bottom:20px;

            .media {
                padding: 1% 15% 0 7%;
                border-left: 1px solid $light-gray;
            }
        }
    }

    li.header-icon:first-child .media {
        border-left: none;
       
    }

    li.header-icon:first-child a {
        padding-left: 0;
    }

    li.header-icon:last-child {
        padding-right: 0;
    }
}

#header-account-icon {

    @media (max-width: $screen-xs-max) {
        width: 100%;
        text-align: right;
        position: static;

        > a {
            display: inline-block;
            padding: 10px 0;
        }

        .media {
            padding: 2px 2px 0 0;
            min-width: 0;
        }
    }

    .dropdown-menu {
        background-color: $white;
        border: none;
        border-radius: 0;
        color: $black;
        margin-top: 0;
        padding: 25px;
        position: absolute;
        top: auto;
        left: 10px;
        right: 10px;
        // desktops
        @media only screen and (min-width: $screen-sm-min) {
            left: -144px;
            position: absolute;
            top: auto;
            right: auto;
        }

        li {
            border-top: 1px solid darken($light-gray, 5%);
            width: 100%;

            #btn-my-account-sign-in {
                background-color: $collections-green;
                border-radius: 2px;
                color: $white;
                padding: 10px 102px;
                width: 100%;
            }

            #btn-my-account-sign-in:hover {
                background-color: darken($mostly-darker-green, 5%);
            }

            a {
                color: $green;
                padding-bottom: 10px;
                padding-top: 10px;
            }

            a:hover {
                background-color: $white;
                width: 100%;
            }

            .menu-item-header {
                padding-left: 8px;
            }
        }

        li.CreateAnAccount {
            border: none;

            @media only screen and (max-width: $screen-xs-max) {
                a {
                    padding-left: 8px;
                }
            }
        }

        li:first-child {
            border-top: none;
        }

        li:last-of-type {
            padding-top: 10px;
        }
    }
}




#header-account-icon.open {
    background-color: $white;

    > a {
        background-color: $white;
    }
}

#header-cart {
    .fa-circle {
        color: $gold;
    }

    .cart-item-count {
        color: $white;
        font-size: 12px;
    }

    .fa-stack {
        margin-bottom: 17px;
    }

    .fa-stack > .fa-circle,
    .fa-stack > .cart-item-count {
        margin-left: 25px;
        margin-top: -9px;
        text-align: center;
        width: 16px;
    }
}

#header-cart:active,
#header-cart:focus,
#header-cart:hover {
    text-decoration: none;

    .fa-circle {
        color: darken($gold, 5%);
    }
}


/* tablets */
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .cart-icon-container {
        position: relative;

        > .circle-icon {
            font-size: 22px;
            top: -5px;
            left: 8px;
        }

        > .cart-items {
            color: $white;
            left: 8px;
        }
    }
    li.header-icon a .media {
    border:none;
    }
}
