﻿
.badgeblock {
    padding: 0;
}

.product-listing-badge-block {
    z-index: 2;
    background-color: $darker-than-light-gray;
    position: absolute;
}

.badge-position-, .badge-position-top, .badge-position-bottom {
    text-align: center;
    width: 200px;
    height: 20px;
}

.badge-position-bottom {
    top: 181px;
}

.badged-image-block {
    .image-container {
        width: 100%;
        padding: 0px;

        .caption {
            font-size: $font-size-large;
            padding: 5px 0;
            position: absolute;
            text-align: center;
            width: 100%;
        }

        .caption-bottom {
            bottom: 0;
        }

        .caption-top {
            top: 0;
        }
    }
}

.button-image-block,
.bordered-button-image-block {
    .btn.btn-shop-now {
        border-radius: 2px;
        font-weight: bold;
        padding: 6px 22px;
    }
}

.button-image-block {
    .image-container {
        position: relative;
        display: inline-block;
        text-align: center;

        .shop-now-caption {
            position: absolute;
            bottom: 20px;
            right: 20px;
            text-align: right;

            h3 {
                color: white;
                font-family: $pt-serif;
                font-weight: bold;
            }

            .btn.btn-shop-now {
                background-color: $beige;
                color: black;
            }

            .btn.btn-shop-now:hover {
                background-color: darken($beige, 5%);
            }
        }
    }

    @media (max-width: $screen-xs) {
        .shop-now-caption {
            h3 {
                font-size: $font-size-base;
            }
        }
    }
}

@media (max-width: $screen-sm-min) {
    .fa-chevron-left {
        margin-left: 0px;
    }

    .fa-chevron-right {
        margin-right: 0px;
    }
}

.captioned-image-block {
    margin-bottom: 10px;
    margin-top: 10px;

    .caption {
        font-size: $font-size-large;
        margin-top: 8px;
        text-align: center;
    }

    &.visual-nav {
        .caption {
            font-size: $font-size-small;
        }
    }
}

.bordered-button-image-block {
    .thumbnail {
        background-color: $light-gray;
        border: 1px solid darken($light-gray, 5%);
    }

    h3 {
        font-family: $pt-serif;
        text-align: center;
    }

    .btn.btn-shop-now {
        background-color: $green;
        color: white;
    }

    .btn.btn-shop-now:hover {
        background-color: $mostly-darker-green;
    }

    img {
        max-height: 150px;
    }
}

.slider-block {
    .carousel-control {
        .fa-chevron-left,
        .fa-chevron-right {
            display: inline-block;
            margin-top: -10px;
            position: absolute;
            top: 50%;
            z-index: 5;
        }

        .fa-chevron-left {
            left: 50%;
        }

        .fa-chevron-right {
            right: 50%;
        }
    }

    @media (min-width: $screen-sm-min) {
        .carousel-control {
            .fa-chevron-left,
            .fa-chevron-right {
                font-size: 30px;
                height: 30px;
                margin-top: -10px;
                width: 30px;
            }

            .fa-chevron-left {
                margin-left: -70px;
            }

            .fa-chevron-right {
                margin-right: -70px;
            }
        }
    }
}
