
:focus {
    outline-width: 2px !important;
    outline-style: solid !important;
    outline-color: Highlight !important;
}

.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.small-gutter > [class*='col-'] {
  padding-right: 11px;
  padding-left: 11px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quantity, .cart-quantity, .cart-item-quantity {
  -moz-appearance: textfield !important;
}

.form-control:focus, .form-control:hover {
  border-color: $green;
  box-shadow: inset 0 1px 1px rgba(77,124,110, 0.075), 0 0 8px rgba(77,124,110, 0.6);
}
input.form-control {
    height: 35px;
}
// homepage success button
.btn-success {
    background-color: $darker-green;
}
// hawksearch search suggestions
.hawk-searchQuery {
    border-width: 0 2px !important;
    box-shadow: none;

    > .hawk-sqHeader,
    > .hawk-sqFooter {
        border-top-color: $almost-even-darker-gray;
    }

    > .hawk-sqFooter {
        border: {
            bottom: 2px solid $almost-even-darker-gray;
            left-width: 0;
            right-width: 0;
        }
    }
}
//homepage
.carousel-indicators {
    bottom: 0px;
}
.carousel-control {
    &.right {
        background-image: none;
    }

    &.left {
        background-image: none;
    }
}

//screenreader
.sr-only:focus {
    color: black;
    display: inline-block;
    height: auto;
    width: auto;
    position: static;
    margin: auto;
}
