//
// Checkboxes
// --------------------------------------------------


$font-family-icon: 'Font Awesome 5 Free' !default;
$fa-var-check: "\f00c" !default;
$check-icon: $fa-var-check !default;
/*@import "../bootstrap-3-4-1/variables";
@import "../font-awesome/_font-awesome";*/
@import "../bootstrap-3-4-1/_variables";
@import "../font-awesome/_font-awesome";


@mixin checkbox-variant($parent, $color) {
    #{$parent} input[type="checkbox"]:checked + label,
    #{$parent} input[type="radio"]:checked + label {
        &::before {
            background-color: $color;
            border-color: $color;
        }

        &::after {
            color: #fff;
        }
    }
}

@mixin checkbox-variant-indeterminate($parent, $color) {
    #{$parent} input[type="checkbox"]:indeterminate + label,
    #{$parent} input[type="radio"]:indeterminate + label {
        &::before {
            background-color: $color;
            border-color: $color;
        }

        &::after {
            background-color: #fff;
        }
    }
}



.checkbox {
    padding-left: 20px;

    label {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        padding-left: 5px;

        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            width: 17px;
            height: 17px;
            left: 0;
            margin-left: -20px;
            border: 1px solid $input-border;
            border-radius: 0px;
            background-color: #fff;
            @include transition(border 0.15s ease-in-out, color 0.15s ease-in-out);
        }

        &::after {
            display: inline-block;
            position: absolute;
            width: 16px;
            height: 16px;
            left: 0;
            top: 0;
            margin-left: -20px;
            padding-left: 3px;
            padding-top: 1px;
            font-size: 11px;
            color: $input-color;
        }
    }

    input[type="checkbox"],
    input[type="radio"] {
        opacity: 0;
        z-index: 1;
        cursor: pointer;

        &:focus + label::before {
            @include tab-focus();
        }

        &:checked + label::after {
            font-family: $font-family-icon;
            content: $check-icon;
        }

        &:indeterminate + label::after {
            display: block;
            content: "";
            width: 10px;
            height: 3px;
            background-color: #555555;
            border-radius: 0px;
            margin-left: -16.5px;
            margin-top: 7px;
        }

        &:disabled {
            cursor: not-allowed;
        }

        &:disabled + label {
            opacity: 0.65;

            &::before {
                background-color: $input-bg-disabled;
                cursor: not-allowed;
            }
        }
    }

    &.checkbox-circle label::before {
        border-radius: 50%;
    }

    &.checkbox-inline {
        margin-top: 0;
    }
}

@include checkbox-variant('.checkbox-primary', $brand-primary);
@include checkbox-variant('.checkbox-danger', $brand-danger);
@include checkbox-variant('.checkbox-info', $brand-info);
@include checkbox-variant('.checkbox-warning', $brand-warning);
@include checkbox-variant('.checkbox-success', $brand-success);


@include checkbox-variant-indeterminate('.checkbox-primary', $brand-primary);
@include checkbox-variant-indeterminate('.checkbox-danger', $brand-danger);
@include checkbox-variant-indeterminate('.checkbox-info', $brand-info);
@include checkbox-variant-indeterminate('.checkbox-warning', $brand-warning);
@include checkbox-variant-indeterminate('.checkbox-success', $brand-success);

//
// Radios
// --------------------------------------------------

@mixin radio-variant($parent, $color) {
    #{$parent} input[type="radio"] {
        + label {
            &::after {
                background-color: $color;
            }
        }

        &:checked + label {
            &::before {
                border-color: $color;
            }

            &::after {
                background-color: $color;
            }
        }
    }
}

.radio {
    padding-left: 20px;

    label {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        padding-left: 5px;

        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            width: 17px;
            height: 17px;
            left: 0;
            margin-left: -20px;
            border: 1px solid $input-border;
            border-radius: 50%;
            background-color: #fff;
            //@include transition(border 0.15s ease-in-out);
        }

        &::after {
            display: inline-block;
            position: absolute;
            content: " ";
            width: 11px;
            height: 11px;
            left: 3.25px;
            top: 1px;
            margin-left: -20px;
            border-radius: 50%;
           
            background-color: $input-color;
            @include scale(0, 0);
            // @include transition-transform(.1s cubic-bezier(.8,-0.33,.2,1.33));
            //curve - http://cubic-bezier.com/#.8,-0.33,.2,1.33
            font-size: 10px;
        
                    
            }

        

    }

    input[type="radio"] {
        opacity: 0;
        z-index: 1;
        cursor: pointer;

        &:focus + label::before {
            @include tab-focus();
            outline-color: transparent; 
        }

        &:checked + label::after {
            @include scale(1, 1);
        }

        &:disabled {
            cursor: not-allowed;
        }

        &:disabled + label {
            opacity: 0.65;

            &::before {
                cursor: not-allowed;
            }
        }
    }

    &.radio-inline {
        margin-top: 0;
    }
}

@include radio-variant('.radio-primary', $brand-primary);
@include radio-variant('.radio-danger', $brand-danger);
@include radio-variant('.radio-info', $brand-info);
@include radio-variant('.radio-warning', $brand-warning);
@include radio-variant('.radio-success', $brand-success);


input[type="checkbox"],
input[type="radio"] {
    &.styled:checked + label:after {
        font-family: $font-family-icon;
        content: $check-icon;
       
    }

    .styled:checked + label {
        &::before {
            color: #fff;
        }

        &::after {
            color: #fff;
        }
    }
}


.checkbox input[type=checkbox]:checked + input[type="hidden"] + label:after,
.checkbox input[type=checkbox]:checked + label:after {
    font-family: 'Font Awesome 5 Free';
    content: "\f00c";
    font-weight: 900;
}

.radio input[type=radio]:checked + input[type="hidden"] + label:after,
.radio input[type=radio]:checked + label:after {
    font-family: 'Font Awesome 5 Free';
    content: "\f111";
    background-color: white;
    font-weight: 900;
}

/*.form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
}*/




