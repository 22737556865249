﻿
a {
    color: $black;
    background-color: $transparent;

    &:link,
    &:visited,
    &:hover,
    &:focus,
    &:active {
        color: $black;
        background-color: $transparent;
    }

    &:link,
    &:visited {
        text-decoration: none;
    }

    &:hover,
    &:focus,
    &:active {
        text-decoration: underline;
    }

    &.none {
        text-decoration: none;
    }

    &.no-hover {
        text-decoration: none !important;

        &:hover {
            text-decoration: none !important;
        }
    }

    &.underline {
        text-decoration: underline;
    }
}
