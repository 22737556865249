
@mixin block-link($height) {
    display: block;
    height: $height;
}

@mixin button-colors($bkg-color, $bdr-color, $clr: $white) {
    background-color: $bkg-color !important;
    border-color: $bdr-color !important;
    color: $clr !important;
}
