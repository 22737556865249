﻿
/* big check labels */
.label-big-check {
    position: relative;
    display: block;
    padding: 15px 0 15px 35px;
    margin: 0;
    border-top: 1px solid $just-about-lightest-gray;
    cursor: pointer;
}

.label-big-check input {
    display: none;
}

.label-big-check .check-title {
    font-size: .9em;
    font-weight: 700;
    cursor: pointer;
}

.label-big-check input[type="checkbox"] + .check-title:before {
    position: absolute;
    top: 14px;
    left: 8px;
    width: 30px;
    font-family: "Font Awesome 5 Free";
    content: "\f0c8";
    font-size: 2em;
    line-height: 1;
    font-weight: 400;
    color: $green;
}

.label-big-check input[type="checkbox"]:checked + .check-title:before {
    content: "\f14a";
    color: $green;
}

.check-title {
    pointer-events: none;
    cursor: default;
}
