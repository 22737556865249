.brand-menu {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    border-bottom: 3px solid $almost-darken-green;
    padding: 0 8px;

    @media (min-width: $screen-md-min) {
        margin-top: -10px;
    }

    &__item {
        margin: 0 6px;

        a {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding: 4px 18px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border-top: 3px solid $transparent;
            border-left: 3px solid $transparent;
            border-right: 3px solid $transparent;
            position: relative;

            @media (min-width: $screen-lg-min) {
                height: 35px;
                min-width: 250px;
                margin-top: 20px;
            }

            @media (max-width: $screen-md-max) {
                height: 30px;
                min-width: 200px;
                margin-top: 14px;
            }

            @media (max-width: $screen-sm-max) {
                min-width: 180px;
            }

            &:hover,
            &:focus {
                margin-top: 0;

                @media (min-width: $screen-lg-min) {
                    height: 55px;
                    padding: 14px 18px;
                }

                @media (max-width: $screen-md-max) {
                    height: 44px;
                    padding: 11px 18px;
                }
            }
        }

        img {
            height: 100%;

            @media (min-width: $screen-lg-min) {
                max-width: 250px;
            }

            @media (max-width: $screen-md-max) {
                max-width: 200px;
            }
        }

        &--active {
            a {
                margin-top: 0;
                background: $white;

                @media (min-width: $screen-lg-min) {
                    height: 55px;
                    padding: 14px 18px;
                }

                @media (max-width: $screen-md-max) {
                    height: 44px;
                    padding: 11px 18px;
                }

                &:after {
                    height: 4px;
                    content: "";
                    background: $white;
                    width: 100%;
                    position: absolute;
                    bottom: -4px;
                    left: 0;
                    z-index: 999;
                }
            }
        }
    }
}
