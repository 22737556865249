﻿
/* HawkSearch Autocomplete */
.hawk-searchQuery {
    border: 2px solid #b6aa79 !important;
}


.hawk-searchQuery .hawk-sqHeader,
.hawk-searchQuery .hawk-sqFooter {
    color: #222222 !important;
    background: #fff !important;
    border-top: 2px solid #ded8c2;
}

.hawk-searchQuery .hawk-sqItemContent {
    margin-left: 0 !important;
}

.hawk-searchQuery .hawk-sqItem {
    border: 0 !important;
    color: #222222 !important;
}

.hawk-searchQuery .hawk-sqItemName,
.hawk-searchQuery .hawk-sqItemName b {
    font-size: 14px !important;
    /*font-weight: normal;*/
}

li.hawk-sqItem.hawk-sqItemAlt {
    background-color: #fff;
}

li.hawk-sqItem.hawk-sqActive {
    border: 0;
    background-color: #f2efe2;
}

.hawk-searchQuery .hawk-sqFooter:hover {
    background-color: #f2efe2 !important;
}

.hawk-searchQuery .hawk-sqFooter a,
.hawk-searchQuery .hawk-sqFooter a:link,
.hawk-searchQuery .hawk-sqFooter a:visited {
    color: #222222 !important;
    font-size: 14px !important;
}

.hawk-searchQuery {
    position: absolute;
    display: none;
    width: 300px;
    margin: 5px 0 0 0;
    background: #fff;
    border: 1px solid #AAAAAA !important;
    -webkit-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
    -webkit-box-shadow: 0 0 4px 1px rgba(50,50,50,0.25);
    box-shadow: 0 0 4px 1px rgba(50,50,50,0.25);
    z-index: 999999;
    text-align: left;
}

.hawk-searchQuery-keyword {
    margin-left: -1px;
}

.hawk-searchQuery .hawk-sqHeader {
    padding: 5px;
    color: #646669;
    background: #d9d9d9;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    font-size: 18px;
}

.hawk-searchQuery .hawk-sqContent {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.hawk-searchQuery .hawk-sqItem {
    position: relative;
    display: block;
    margin: 0;
    padding: 6px 10px;
    color: #8b2300;
    text-decoration: none;
    border: 1px solid #fff;
    cursor: pointer;
    zoom: 1;
}

.hawk-searchQuery .hawk-sqItemAlt {
    background-color: #f3f3f3;
}

.hawk-searchQuery .hawk-sqActive {
    border-color: #8b2300;
}

/* embedded clearfix on results item: h5bp.com/q */
.hawk-searchQuery .hawk-sqItem:before, .hawk-searchQuery .hawk-sqItem:after {
    content: "";
    display: table;
}

.hawk-searchQuery .hawk-sqItem:after {
    clear: both;
}

.hawk-searchQuery .hawk-sqItemImage {
    display: inline;
    float: left;
    width: 70px;
    height: auto;
    margin: 0;
    text-align: center;
}

.hawk-searchQuery .hawk-sqItemContent {
    display: block;
    margin-left: 80px;
}

.hawk-searchQuery .hawk-sqItemName {
    display: block;
    margin: 0;
    font-size: 1em;
    border: 0;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 14px;
}

.hawk-searchQuery .hawk-sqFooter {
    padding: 5px;
    background: #d9d9d9;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    font-size: 18px;
}

.hawk-searchQuery .hawk-sqFooter a,
.hawk-searchQuery .hawk-sqFooter a:link,
.hawk-searchQuery .hawk-sqFooter a:visited {
    display: inline-block;
    padding: 1px 25px 0 0;
    color: #374351;
    font-size: 0.875em;
    font-weight: bold;
    text-decoration: none;
}

.hawk-searchQuery .hawk-footerLink:hover {
    text-decoration: none;
}
